import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import MuiField from './../components/MuiField';
import Button from './../components/Button/Button';
import { 
  styledNamePage,
  header
} from '../components/styles/InternalPage.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>Ferramentas</span>
              Ferramenta de DNS
            </H4>
            <Paragraph>Esta ferramenta de DNS foi desenvolvida pela HostDime e permite que você visualize toda e qualquer informação de DNS sobre um domínio em particular. Nós guardamos em cache todas as consultas anteriores para que você veja as mudanças ao longo do tempo.</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiField
                  variant="outlined"
                  label="Domínio"
                  placeholder='Domínio'
                  style={{
                    maxWidth: 328,
                    marginBottom: 10
                  }}
                />
                <MuiField
                  variant="outlined"
                  label="Idioma"
                  placeholder='English'
                  style={{
                    maxWidth: 328,
                    marginBottom: 24
                  }}
                />
                <Button
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['110px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  Go
                </Button>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='DNS Tools' />
      <PageTemplate
        title='DNS Tools'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
